<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-sm-5">
        <div class="breadcrumb">
          <h1>Modificar Tratamiento <span class="h4 pl-2"> N°: {{numero}}</span></h1>
        </div>
      </div>
      <!-- aqui va la transicion con la barra informativa superior -->
      <transition name="fade">
        <div class="col-sm-7 navbar-reubicado">
          <div class="row">
            <div class="col-sm-12 py-2 bg-sky-light text-white">
              <div class="row">
                <div class="col-2">
                  <p style="font-size: 12px"> F/Nac.: {{ dataPaciente.fecha_nacimiento }} </p>
                </div>
                <div class="col-4" v-show="dataPaciente.contacto">
                  <p style="font-size: 12px">Contacto 1: {{ dataPaciente.contacto }} </p>
                </div>
                <div class="col-4">
                  <p style="font-size: 12px" v-if="dataPaciente.familiar">
                    Familiar 1:
                    {{ dataPaciente.familiar }} </p>
                </div>
              </div>
            </div>
            <!-- <div class="col-sm-1 bg-sky-light pl-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="36"
                fill="currentColor"
                class="bi bi-caret-down-fill text-success" viewBox="0 0 16 16">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4
                 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
            </div> -->
          </div>
        </div>
      </transition>
    </div>
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="card text-left">
          <div class="card-header">
            <div class="col-sm-5">
              <button
                type="submit"
                class="btn bg-custom-green mr-2 rounded"
                :disabled="buttonDisable"
                @click="update"
              >
                Guardar
              </button>
              <router-link :to="{ name: 'tratamiento-index' }">
                <button
                  class="btn btn-white border border-light rounded">
                  Regresar
                </button>
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <b-card class="mb-3">
                  <div class="row mb-3">
                    <div class="col-sm-2 text-right pr-0">
                      <label class="col-form-label">
                        Fecha:
                      </label>
                    </div>
                    <div class="col-sm-4 px-0">
                      <date-picker
                        :disabled="true"
                        class="col-sm-10"
                        v-model="fecha"
                        lang="es"
                        format="DD/MM/YYYY"
                        type="date"
                        value-type="date"
                      ></date-picker>
                    </div>
                    <div class="col-sm-2 text-right pr-0">
                      <label class="col-form-label">
                        Fecha Cierre:
                      </label>
                    </div>
                    <div class="col-sm-4 px-0">
                      <date-picker
                        class="col-sm-10"
                        v-model="fechaCierre"
                        lang="es"
                        format="DD/MM/YYYY"
                        type="date"
                        value-type="date"
                      ></date-picker>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-2 text-right pr-0">
                      <label class="col-form-label">
                        Paciente:
                      </label>
                    </div>
                    <ComboPaciente
                    :paciente="pacienteSelected"
                    :edit="edit"
                    v-on:addPaciente="AddPaciente"
                    :havePreCierre="preCierre"
                    />
                    <div class="col-sm-1 px-0">
                      <button
                        class="btn btn-success col-sm-12 px-0"
                        @click="openModalPaciente"
                      >
                        <i class="i-Business-ManWoman h5"></i>
                      </button>
                      <ModalPacienteNuevo />
                    </div>
                  </div>
                </b-card>
                <b-card class="mb-3">
                  <div class="row mb-3">
                    <div class="col-sm-2 text-right pr-0">
                      <label class="col-form-label">
                        H.C:
                      </label>
                    </div>
                    <div class="col-sm-2 pr-0">
                      <input
                        type="text"
                        class="form-control"
                        v-model="dataPaciente.numero"
                        disabled
                      >
                    </div>
                    <div class="col-sm-1 px-0 text-right">
                      <label class="col-form-label">
                        Edad:
                      </label>
                    </div>
                    <div class="col-sm-2 pr-1">
                      <input
                        type="text"
                        class="form-control"
                        v-model="edad"
                        disabled
                      >
                    </div>
                    <div class="col-sm-2 pl-1">
                      <input
                        v-model="mes"
                        type="text"
                        class="form-control"
                        disabled
                      >
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-2 text-right pr-0">
                      <label class="col-form-label text-light">
                        Almacen:
                      </label>
                    </div>
                    <div class="col-sm-7">
                      <multiselect2
                        v-model="almacen.model"
                        selectLabel=""
                        selectedLabel=""
                        deselect-label="X"
                        track-by="almacen"
                        label="almacen"
                        placeholder="Seleccione Almacen"
                        :options="almacen.listaAlmacenes"
                        :disabled="isDisabledAlmacen"
                        :allow-empty="false"
                        :searchable="true">
                      </multiselect2>
                    </div>
                  </div>
                  <div class="row mb-3" v-if="isDisableSeguro">
                    <div class="col-sm-2 text-right pr-0">
                      <label class="col-form-label text-light">
                        Seguro:
                      </label>
                    </div>
                    <div class="col-sm-7">
                      <multiselect2
                        v-model="seguro.model"
                        selectLabel=""
                        selectedLabel=""
                        deselect-label="X"
                        track-by="nombre_comercial"
                        label="nombre_comercial"
                        placeholder="Seleccione un seguro"
                        :options="seguro.listaSeguros"
                        :allow-empty="false"
                        :searchable="true">
                      </multiselect2>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-sm-2 pr-1">
                      <button
                        class="btn col-sm-12"
                        :class="getIDItemChecked !== null? 'btn-success':'btn-warning'"
                        @click="openModalPieza"
                      >
                        Pieza
                      </button>
                      <ModalPieza :editMode="true"/>
                    </div>
                    <div class="col-sm-3 pr-1">
                      <input
                        :value="getCamaItemChecked"
                        type="text"
                        class="form-control text-center px-1"
                        disabled
                      >
                    </div>
                    <div class="col-sm-4 pl-1">
                      <input
                        :value="getPisoSalaItemChecked"
                        type="text"
                        class="form-control text-center px-1"
                        disabled
                      >
                    </div>
                  </div>
                </b-card>
                <b-card class="mb-4">
                  <div class="row mb-3">
                    <div class="col-sm-3 px-0 ml-2 text-right">
                      <label class="col-form-label text-skyblue font-weight-bold">
                       Tratamiento Principal:
                      </label>
                    </div>
                    <div class="col-sm-8">
                      <multiselect2
                        v-model="categoriaTratamiento.model"
                        selectLabel=""
                        selectedLabel=""
                        deselect-label="X"
                        track-by="nombre"
                        label="nombre"
                        multiple
                        placeholder="Seleccione tratamientos"
                        :options="categoriaTratamiento.listaCategorias"
                        :allow-empty="false"
                        :searchable="true">
                      </multiselect2>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-3 px-0 ml-2 text-right">
                      <label class="col-form-label text-skyblue font-weight-bold">
                       Medico Principal:
                      </label>
                    </div>
                    <div class="col-sm-8">
                      <multiselect2
                        v-model="medico.model"
                        selectLabel=""
                        selectedLabel=""
                        deselect-label="X"
                        track-by="nombre"
                        label="nombre"
                        placeholder="Seleccione un medico"
                        :options="medico.listaMedicos"
                        multiple
                        :searchable="true">
                      </multiselect2>
                    </div>
                  </div>
                </b-card>
                <div class="row">
                  <div class="col-sm-3 offset-sm-3 pl-0">
                    <TheModalPreCierre
                      ref="theModalPreCierre"
                      :servicios_internos="serviciosFiltered"
                      :cxp="cuentas_x_pagar"
                      :salidProducto="salidaProductos.detalles"
                      :paciente="dataPaciente"
                      :preCierreData="preCierreData"
                      :editMode="true"
                      :moneda="moneda"
                      @agregarPrecierre="agregarPrecierre"
                      @update:estado_precierre="preCierre=$event"
                      v-on:limpiarPreCierre="limpiarPreCierre"
                      @agregarCierre="agregarCierre"/>
                  </div>
                  <div class="col-sm-3 pr-0">
                    <ModalCierre
                    :preCierre="preCierre"
                    :preCierreData="preCierreData"
                    :cierreData="cierre"
                    @agregarCierre="agregarCierre"/>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="row mb-2">
                  <div class="col-sm-12 border border-light py-2 px-4">
                    <div class="row mb-1">
                      <div class="col-sm-12 bg-light text-white rounded">
                        <div class="row">
                          <div class="col-sm-11 h4">
                            <label class="col-form-label">
                              Consumo de Productos (SP)
                            </label>
                          </div>
                          <div class="col-sm-1 pl-0 pt-2">
                            <the-modal-consumo-productos
                              :almacen="almacen.model"
                              :moneda="moneda.model"
                              :fecha="fecha"
                              :sugerencia_glosas="sugerencias_glosas_sp"
                              :detalles="productosEdit"
                              :havePreCierre="preCierre"
                              v-on:addProducto="addProducto"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 rounded th-custom-color text-black py-2">
                        <div class="row">
                          <div class="col-sm-1 font-weight-bold"> &nbsp;&nbsp;</div>
                          <div class="col-sm-3 font-weight-bold pr-0">Fecha</div>
                          <div class="col-sm-6 font-weight-bold">Productos</div>
                          <div class="col-sm-2 font-weight-bold text-center">Cantidad</div>
                        </div>
                      </div>
                      <div class="col-sm-12"
                        :class="consumoProductos.length > 3? 'tabla-scroll-vertical': ''">
                        <template v-if="consumoProductos.length > 0">
                          <div class="row mb-2 border-light pt-2"
                            v-for="(item, index) in consumoProductos" :key="''+item.id + index"
                            :class="index === 0 ? '': 'border-top'">
                            <div class="col-sm-1 pr-0">
                              <i class="nav-icon i-Close-Window text-danger font-weight-bold"
                                v-if="!preCierre"
                                style="cursor: pointer"  @click="eliminarProducto(index)"></i>
                              <i v-else
                              class="nav-icon i-Close-Window font-weight-bold"
                              style="cursor: pointer"
                              :title="messagePreCierre"
                              ></i>
                            </div>
                            <div class="col-sm-3 p-0">
                              {{ getFechaFormat(item.created_at, 'DD/MM/YYYY-HH:mm') }} <br>
                              {{item.usuario}}
                            </div>
                            <div class="col-sm-6 p-0">
                              {{item.numero}} - {{item.producto_nombre}}
                              <strong>Glosa: </strong> {{ item.glosa }}
                            </div>
                            <div class="col-sm-2 text-center">
                              <button
                              :class="item.tipo_consumo === 1
                              ? 'btn btnOrange' : 'btn btnTurquesa'">
                                {{item.cantidad}}
                              </button>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div
                            class="row mb-2 border-light pt-2 border-top">
                            <div class="col-sm-12 text-center">
                              No hay consumo productos registrados
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-12 border border-light py-2 px-4">
                    <div class="row mb-1">
                      <div class="col-sm-12 bg-light text-white rounded">
                        <div class="row">
                          <div class="col-sm-11 h4">
                            <label class="col-form-label">
                              Servicios Internos (VS)
                            </label>
                          </div>
                          <div class="col-sm-1 pl-0 pt-2">
                            <TheModalServicioInterno
                            ref="servicioInterno"
                            @addServiciosInternos="addServiciosInternos"
                            :empresa_moneda="empresa_moneda"
                            :sugerencia_glosas="sugerencias_glosas"
                            :medic="medico.listaMedicos"
                            :servicios_internos="serviciosAllInternos"
                            :havePreCierre="preCierre"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 rounded th-custom-color text-black py-2">
                        <div class="row">
                          <div class="col-sm-1 font-weight-bold"> &nbsp;&nbsp;</div>
                          <div class="col-sm-3 font-weight-bold">Fecha</div>
                          <div class="col-sm-6 font-weight-bold">Servicios</div>
                          <div class="col-sm-2 font-weight-bold text-center">Hora</div>
                        </div>
                      </div>
                      <div class="col-sm-12"
                        :class="serviciosFiltered.length > 2? 'tabla-scroll-vertical': ''">
                        <template v-if="serviciosFiltered.length > 0">
                          <div
                            class="row mb-2 border-light pt-2"
                            v-for="(item, index) in serviciosFiltered" :key="item.id"
                            :class="index === 0 ? '': 'border-top'"
                          >
                            <div class="col-sm-1">
                              <!-- <i
                                class="nav-icon i-Pen-5 text-success font-weight-bold"
                                style="cursor: pointer"
                                @click="showModalServicioInterno(item)"
                              >
                              </i> -->
                              <i class="nav-icon i-Close-Window text-danger font-weight-bold"
                                style="cursor: pointer"
                                v-if="!preCierre"
                                @click="eliminarServiciosInternos(item)"></i>
                              <i v-else
                                class="nav-icon i-Close-Window font-weight-bold"
                                style="cursor: pointer"
                                :title="messagePreCierre"
                              ></i>
                            </div>
                            <div class="col-sm-3">
                              {{item.venta_servicio_fecha}}
                              <br>
                              <b>User: </b> {{ item.usuario }}
                            </div>
                            <div class="col-sm-6">
                              {{item.tipo_servicio_nombre}}
                              <br>
                              <b>Glosa: </b> {{ item.textoglosa }}
                            </div>
                            <div class="col-sm-2 text-center">
                              {{item.hora}}
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div
                            class="row mb-2 border-light pt-2 border-top">
                            <div class="col-sm-12 text-center">
                              No hay servicios internos registrados
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 border border-light py-2 px-4">
                    <div class="row mb-1">
                      <div class="col-sm-12 bg-light text-white rounded">
                        <div class="row">
                          <div class="col-sm-11 h4">
                            <label class="col-form-label">
                              Servicios Externos (CxP)
                            </label>
                          </div>
                          <div class="col-sm-1 pl-0 pt-2">
                            <!-- <button class="btn btn-success pr-3">+</button> -->
                            <the-modal-servicio-externo
                              :data="dataModalCXP"
                              v-on:addCuentaPorPagar="editAddCuentaPorPagar"
                              :havePreCierre="preCierre"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 rounded th-custom-color text-black py-2">
                        <div class="row">
                          <div class="col-sm-1 font-weight-bold"> &nbsp;&nbsp;</div>
                          <div class="col-sm-3 p-0 font-weight-bold">Fecha</div>
                          <div class="col-sm-6 font-weight-bold">Servicios</div>
                          <div class="col-sm-2 font-weight-bold text-center">Hora</div>
                        </div>
                      </div>
                      <div class="col-sm-12"
                        :class="cuentas_x_pagar.length > 3? 'tabla-scroll-vertical': ''">
                        <template v-if="cuentas_x_pagar.length > 0">
                          <div
                            class="row mb-2 border-light pt-2"
                            v-for="(item, index) in cuentas_x_pagar" :key="''+item.id + index"
                            :class="index === 0 ? '': 'border-top'">
                            <div class="col-sm-1">
                              <the-modal-servicio-externo
                                :data="dataModalCXP"
                                :editCxPIndex="index"
                                :havePreCierre="preCierre"
                                v-on:addCuentaPorPagar="editAddCuentaPorPagar"/> &nbsp;&nbsp;
                              <i class="nav-icon i-Close-Window text-danger font-weight-bold"
                                v-if="!preCierre"
                                style="cursor: pointer"  @click="eliminarCuentaPorPagar(index)"></i>
                              <i v-else
                                class="nav-icon i-Close-Window font-weight-bold"
                                style="cursor: pointer"
                                :title="messagePreCierre"
                              ></i>
                            </div>
                            <div class="col-sm-3 p-0">
                              {{ getFechaFormat(item.fecha_deuda,'DD/MM/YYYY') }} <br>
                              {{item.usuario}}
                            </div>
                            <div class="col-sm-6 p-0">
                              {{item.origen_type}}: {{item.numero}} - Dr. {{item.medico}} -
                              <strong>Desc.: </strong>
                              {{item.glosa  ? item.glosa : item.descripcion}}
                            </div>
                            <div class="col-sm-2 text-center">
                              {{ getFechaFormat(item.created_at,'HH:mm')}}
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div
                            class="row mb-2 border-light pt-2 border-top">
                            <div class="col-sm-12 text-center">
                              No hay servicios externos registrados
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store/index';
import Multiselect2 from '@/components/util/vue-multiselect2';
import DatePicker from 'vue2-datepicker';
import axios from '@/modules/common/axiosERP';
import moment from 'moment';
import { util } from '@/plugins/util';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ComboPaciente from '../components/ThePatient.vue';
import ModalPacienteNuevo from '../components/TheModalPacienteNuevo.vue';
import ModalPieza from '../components/TheModalPieza.vue';
import TheModalServicioExterno from '../components/TheModalServicioExterno.vue';
import TheModalConsumoProductos from '../components/TheModalConsumoProductos.vue';
import TheModalServicioInterno from '../components/TheModalServicioInterno.vue';
import TheModalPreCierre from '../components/TheModalPreCierre.vue';
import ModalCierre from '../components/TheModalCierre.vue';

export default {
  name: 'edit',
  components: {
    Multiselect2,
    DatePicker,
    ComboPaciente,
    ModalPacienteNuevo,
    ModalPieza,
    TheModalServicioExterno,
    TheModalConsumoProductos,
    TheModalServicioInterno,
    TheModalPreCierre,
    ModalCierre,
  },
  data() {
    return {
      id: atob(this.$route.params.id),
      isLoading: true,
      fecha: null,
      pacienteSelected: null,
      pacientes: [],
      servicios: [],
      fechaCierre: new Date(),
      categoriaTratamiento: {
        listaCategorias: [],
        model: null,
      },
      medico: {
        listaMedicos: [],
        model: [],
      },
      seguro: {
        listaSeguros: [],
        model: null,
      },
      almacen: {
        listaAlmacenes: [],
        model: null,
      },
      numero: '',
      edit: true,
      dataPaciente: {},
      edad: '',
      mes: '',

      // CXP
      moneda: {
        model: null,
        multiselect: [],
        nuevo_tipo_cambio: null,
        tc_secundario: null,
      },
      cxp: {},
      dataModalCXP: {},
      cuentas_x_pagar: [],
      cuentas_x_pagar_origin: [],

      // Consumo Productos SP
      consumoProductos: [],
      salidaProductos: {
        total: 0,
        detalles: [],
      },
      sugerencias_glosas_sp: [],
      productosEdit: [],

      sugerencias_glosas: [],
      servicios_origin: [],
      serviciosAllInternos: [],
      // anticipo: 0,
      preCierre: false,
      categoriaServiciosInternos: [],
      preCierreData: {
        id: null,
        total_precierre: 0,
        saldo_precierre: 0,
        anticipo_precierre: 0,
        glosa_precierre: null,
        estado_precierre: false,
        custom_id: null,
        fecha: null,
        hora: null,
        usuario: null,
      },
      cierre: {
        fecha: null,
        hora: null,
        glosa: null,
        estado: false,
      },
      empresa_moneda: null,
      messagePreCierre: 'Acción no permita usted tiene un precierre',
      isDisableSeguro: store.getters['main/hasPermisionListarSeguro'],
      buttonDisable: false,
    };
  },
  created() {
    this.cargarDatosIniciales();
  },
  beforeDestroy() {
    this.resetModalPieza();
  },
  methods: {
    async cargarDatosIniciales() {
      this.isLoading = true;
      try {
        const RESPONSE = await axios.get(`clinic/tratamientos/${this.id}/edit`);
        this.categoriaTratamiento.listaCategorias = RESPONSE.data.data.categoria_tratamientos;
        this.medico.listaMedicos = RESPONSE.data.data.medicos;
        this.seguro.listaSeguros = RESPONSE.data.data.seguros;
        this.almacen.listaAlmacenes = RESPONSE.data.data.almacens;
        this.fecha = new Date(moment(RESPONSE.data.data.data_tratamientos.fecha));
        this.fechaCierre = new Date(moment(RESPONSE.data.data.data_tratamientos.fecha_cierre));
        this.numero = RESPONSE.data.data.data_tratamientos.numero;
        this.pacienteSelected = RESPONSE.data.data.paciente;
        this.dataPaciente = RESPONSE.data.data.paciente;
        this.edad = this.dataPaciente.edad;
        this.mes = this.dataPaciente.meses;
        this.medico.model = RESPONSE.data.data.tratamiento_medicos;
        this.seguro.model = this.seguro.listaSeguros.find((ele) => (
          ele.id === RESPONSE.data.data.data_tratamientos.seguro_id));
        this.almacen.model = this.almacen.listaAlmacenes.find((ele) => (
          ele.id === RESPONSE.data.data.data_tratamientos.almacen_id));
        this.categoriaTratamiento.model = RESPONSE.data.data.detalle_categorias_tratamientos;
        this.cargarModalPieza(RESPONSE.data.data.sala_camas);
        this.cargarSalaCamaID(RESPONSE.data.data.data_tratamientos.sala_cama_id);
        // carga datos Modal cxp
        this.moneda.multiselect = RESPONSE.data.data.monedas;
        this.moneda.model = this.loadMonedaPrincipal(this.moneda.multiselect);
        // this.dataModalCXP.medicos = this.medico.listaMedicos;
        this.dataModalCXP.medicos = RESPONSE.data.data.medicos;
        this.dataModalCXP.monedas = this.moneda;
        this.dataModalCXP.parametrosContables = RESPONSE.data.data.pametrosContables;
        this.dataModalCXP.tipo_comprobantes = RESPONSE.data.data.tipo_comprobante
          .filter((ele) => (ele.id === 1 || ele.id === 4));
        this.dataModalCXP.cuenta_credito_fiscal = RESPONSE.data.data.cuenta_credito_fiscal;
        this.cuentas_x_pagar = RESPONSE.data.data.cuentas_x_pagar;
        this.cuentas_x_pagar.forEach((item, index) => {
          this.cuentas_x_pagar[index].importe_neto = item.importe_s_d - item.descuento;
          this.cuentas_x_pagar[index].importe = item.importe_s_d;
        });
        this.cuentas_x_pagar_origin = this.cloneObject(RESPONSE.data.data.cuentas_x_pagar);
        this.dataModalCXP.tipoFacturas = RESPONSE.data.data.tipo_facturas;
        this.dataModalCXP.cuentas_x_pagar = this.cuentas_x_pagar;

        // Consumo Productos SP
        this.consumoProductos = RESPONSE.data.data.consumo_productos;
        this.sugerencias_glosas_sp = RESPONSE.data.data.sugerencia_glosas_consumo_productos;
        if (this.consumoProductos.length > 0) {
          this.productosEdit = this.cloneObject(this.consumoProductos);
          this.cargarConsumoProductos();
        }

        this.servicios = RESPONSE.data.data.tratamiento_venta_servicios;
        // this.servicios_origin = this.cloneObject(RESPONSE.data.data.tratamiento_venta_servicios);

        this.sugerencias_glosas = RESPONSE.data.data.sugerencia_glosas;
        this.serviciosAllInternos = RESPONSE.data.data.tipo_servicios;
        // this.anticipo = (this.dataPaciente.anticipo) ? this.dataPaciente.anticipo : 0;
        this.categoriaServiciosInternos = RESPONSE.data.data.categoria_medico;
        // this.preCierreData.id = RESPONSE.data.data.data_tratamientos.tratamiento_precierre_id;
        this.cargarModalPreCierre(RESPONSE.data.data.preCierre);
        this.setServicesData();
        this.empresa_moneda = RESPONSE.data.data.empresa_moneda;
      } catch (error) {
        console.log('error', error);
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    openModalPaciente() {
      this.$bvModal.show('modal-pacienteNuevo');
    },
    formatearDate(fecha) {
      const dat = new Date(fecha);
      return new Date(dat.setDate(dat.getDate() + 1));
    },
    AddPaciente(paciente) {
      if (paciente) {
        // this.$refs.theModalPreCierre.restaurar();
        this.preCierre = false;
        this.dataPaciente = paciente.datosPaciente;
        this.edad = (paciente.datosPaciente.edad) ? `${paciente.datosPaciente.edad} años` : '0 años';
        this.mes = (paciente.datosPaciente.meses) ? `${paciente.datosPaciente.meses} mes` : '0 mes';
        // this.showCortinaInformativa = true;
        this.pacienteSelected = paciente.pacienteSelect;
        this.preCierreData.anticipo_precierre = (this.dataPaciente.anticipo)
          ? this.dataPaciente.anticipo : 0;
      } else {
        this.edad = '';
        this.mes = '';
        // this.showCortinaInformativa = false;
        this.pacienteSelected = '';
        this.dataPaciente = '';
      }
    },
    update() {
      if (this.validar()) {
        this.isLoading = true;
        this.preCierreData.estado_precierre = this.preCierre;
        this.cargarDatosPreCierre();
        const DATA = {
          paciente_id: this.pacienteSelected.id,
          fecha: moment(this.fecha).format('YYYY-MM-DD'),
          fecha_cierre: moment(this.fechaCierre).format('YYYY-MM-DD'),
          almacen_id: (this.almacen.model) ? this.almacen.model.id : null,
          seguro_id: (this.seguro.model) ? this.seguro.model.id : '',
          tratamiento_medicos: this.medico.model,
          categoria_tratamientos: this.categoriaTratamiento.model,
          monedas: this.moneda.multiselect,
          cuentas_x_pagar: this.cuentasPorPagarSendData(),
          sala_cama_id: this.getIDItemChecked,
          venta_servicios: this.clearParams(),
          consumo_productos: this.salidaProductosSendData(),
          preCierre: this.preCierreData,
          cierre: this.cierre,
        };
        this.buttonDisable = true;
        axios.put(`clinic/tratamientos/update/${this.id}`, DATA)
          .then((response) => {
            util.showNotify(response.data.message, 'success');
            this.$router.push('/tratamiento');
          })
          .catch((error) => {
            const FALLO_VALIDACION = 422;
            if (error.response.status !== FALLO_VALIDACION) {
              util.showNotify(error.response.data.message, 'error');
            } else {
              Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                mensajes.forEach((texto) => util.showNotify(texto, 'error'));
              });
            }
          })
          .finally(() => {
            this.buttonDisable = false;
            this.isLoading = false;
          });
      }
    },
    clearParams() {
      this.servicios.forEach((e, index) => {
        delete this.servicios[index].customId;
        delete this.servicios[index].usuario;
        delete this.servicios[index].textoglosa;
        delete this.servicios[index].tipo_servicio_nombre;
      });
      return this.servicios;
    },
    validar() {
      let validate = true;
      if (!this.pacienteSelected) {
        util.showNotify('Seleccione un paciente', 'warn');
        validate = false;
      }
      if (!this.fecha) {
        util.showNotify('La fecha es un campo obligatorio', 'warn');
        validate = false;
      }
      if (!this.fechaCierre) {
        util.showNotify('La fecha de cierre es un campo obligatorio', 'warn');
        validate = false;
      }
      if ((this.consumoProductos.length > 0 || this.cuentas_x_pagar_origin.length > 0)
        && !this.almacen.model) {
        util.showNotify('Debe seleccionar un almacen. \n Si va registrar Consumo Productos o Servicios Externos', 'warn');
        validate = false;
      }
      if (this.medico.model.length === 0) {
        util.showNotify('Selecciones un medico', 'warn');
        validate = false;
      }
      if (!this.categoriaTratamiento.model) {
        util.showNotify('Seleccione un tratamiento', 'warn');
        validate = false;
      }
      if (this.fecha && this.fechaCierre) {
        if (this.fecha.getTime() > this.fechaCierre.getTime()) {
          util.showNotify('La fecha de cierre debe ser mayor que la fecha inicio', 'error');
          validate = false;
        }
      }
      // if (!this.preCierre) {
      //   util.showNotify('Debe calcular su pre cierre', 'error');
      //   validate = false;
      // }
      return validate;
    },
    addCuentaPorPagar(cuentaPorPagar) {
      const cxpObj = JSON.stringify(cuentaPorPagar);
      this.cxp = JSON.parse(cxpObj);
      this.cuentas_x_pagar.push(this.cxp);
      // this.$refs.theModalPreCierre.restaurar();
    },
    loadMonedaPrincipal(monedas) {
      for (let i = 0; i < monedas.length; i += 1) {
        if (monedas[i].is_principal === 1) {
          return monedas[i];
        }
      }
      return null;
    },
    transformCXPEdit(cxp) {
      const cxpNew = {
        id: (cxp.id) ? cxp.id : 0,
        persona_id: cxp.medico.persona_id,
        medico: cxp.medico.nombre,
        numero: cxp.numero,
        origen_type: cxp.origen_type,
        tipo_comprobante_id: cxp.tipo_comprobante.id,
        comprobante: cxp.t_comprobante_child,
        pre_retencion_tipo_id: (cxp.preRetencionTipo) ? cxp.preRetencionTipo.id : null,
        nuevo_tipo_cambio: cxp.nuevo_tipo_cambio,
        fecha_deuda: cxp.fecha,
        moneda_id: cxp.moneda.id,
        cuenta_contable_id: cxp.cuenta_pasivo_id,
        cuenta_contable_gasto_id: cxp.cuenta_gasto_id,
        descripcion: cxp.glosa,
        importe: cxp.importe,
        retencion: cxp.retencion,
        importe_neto: cxp.importe_neto,
        descuento: cxp.descuento,
        fecha_vencimiento: cxp.fecha_vencimiento,
        factura: (cxp.tipo_comprobante.id === 1) ? cxp.factura : null,
        index: cxp.index,
        usuario: cxp.usuario,
        created_at: cxp.created_at,
        importe_actual: cxp.importe_actual,
      };
      if (cxp.id && cxp.id > 0) {
        cxpNew.editable = true;
      }
      return cxpNew;
    },
    editAddCuentaPorPagar(cuentaPorPagar) {
      const cxpEdit = this.transformCXPEdit(cuentaPorPagar);
      this.cxp = this.cloneObject(cxpEdit);
      this.agregarCuentasPorPagar(this.cxp);
    },
    eliminarCuentaPorPagar(index) {
      const cxpDel = this.cuentas_x_pagar[index];
      const indexReal = this.cuentas_x_pagar_origin.findIndex((ele) => (ele.id === cxpDel.id));
      if (cxpDel.id > 0) {
        delete this.cuentas_x_pagar_origin[indexReal].editable;
        this.cuentas_x_pagar_origin[indexReal].eliminar = true;
      } else {
        this.cuentas_x_pagar_origin.splice(indexReal, 1);
      }
      this.cuentas_x_pagar.splice(index, 1);
    },
    agregarCuentasPorPagar(cxp) {
      if (cxp.index >= 0) {
        // let index = cxp.index;
        this.cuentas_x_pagar.splice(cxp.index, 1, cxp);
        const indexReal = this.cuentas_x_pagar_origin.findIndex((ele) => (ele.id === cxp.id));
        this.cuentas_x_pagar_origin.splice(indexReal, 1, cxp);
      } else {
        this.cuentas_x_pagar.push(this.cxp);
        this.cuentas_x_pagar_origin.push(this.cxp);
      }
    },
    cuentasPorPagarSendData() {
      const cxps = [];
      this.cuentas_x_pagar_origin.forEach((cxp) => {
        cxps.push({
          id: cxp.id,
          persona_id: cxp.persona_id, // 24002,
          t_comprobante_id: cxp.tipo_comprobante_id,
          t_comprobante_child: cxp.comprobante,
          pre_retencion_tipo_id: (cxp.preRetencionTipo) ? cxp.preRetencionTipo.id : null,
          nuevo_tipo_cambio: (cxp.nuevo_tipo_cambio) ? cxp.nuevo_tipo_cambio : null,
          fecha: cxp.fecha_deuda,
          fecha_hora: this.getFechaFormat(cxp.created_at, 'YYYY-MM-DD HH:mm:ss'),
          moneda_id: cxp.moneda_id,
          cuenta_pasivo_id: cxp.cuenta_contable_id,
          cuenta_gasto_id: cxp.cuenta_contable_gasto_id,
          descripcion: cxp.descripcion,
          importe: cxp.importe,
          retencion: cxp.retencion,
          importe_neto: (cxp.importe_neto) ? cxp.importe_neto : cxp.importe,
          descuento: cxp.descuento,
          sucursal_id: (cxp.sucursal_id) ? cxp.sucursal_id : null,
          fecha_vencimiento: cxp.fecha_vencimiento,
          factura: ((cxp.tipo_comprobante_id === 1)) ? cxp.factura : null,
          editar: cxp.editable,
          eliminar: cxp.eliminar,
          tributo_id: cxp.tributo_id ? cxp.tributo_id : null,
          importe_actual: cxp.importe_actual,
        });
      });
      return cxps;
    },
    cloneObject(object) {
      const objectString = JSON.stringify(object);
      return JSON.parse(objectString);
    },
    getFechaFormat(dateString, formato) {
      return moment(dateString).format(formato);
    },
    addProducto(detalles) {
      const productoObj = this.cloneObject(detalles);
      this.salidaProductos.detalles = [];
      this.consumoProductos = [];
      for (let index = 0; index < productoObj.length;) {
        const ele = productoObj[index];
        index += 1;
        this.consumoProductos.push({
          producto_id: ele.producto_id,
          cantidad: ele.cantidad,
          created_at: ele.created_at ? ele.created_at : ele.fecha,
          numero: ele.numero ? ele.numero : 'Local',
          producto_nombre: ele.producto_nombre,
          glosa: ele.glosa,
          usuario: ele.usuario,
          tipo_consumo: ele.tipo_consumo,
        });
        const moneda = this.moneda.multiselect.find((mo) => mo.id === ele.moneda_id);
        if (moneda.is_principal !== 1) {
          // eslint-disable-next-line operator-assignment
          ele.producto_precio = moneda.tc * ele.producto_precio;
        }
        ele.total_venta = ele.producto_precio * ele.cantidad;
      }
      this.salidaProductos.detalles = productoObj;
    },
    eliminarProducto(index) {
      this.consumoProductos.splice(index, 1);
      this.salidaProductos.detalles.splice(index, 1);
      this.$emit('delDetalle', index);
    },
    cargarConsumoProductos() {
      this.salidaProductos.salida_producto_id = this.consumoProductos[0].salida_producto_id;
      this.salidaProductos.moneda_id = this.consumoProductos[0].moneda_id;
      this.salidaProductos.cuenta_contable_id = this.consumoProductos[0].cuenta_contable_id;
      this.salidaProductos.total = parseFloat(this.consumoProductos[0].total);
      this.consumoProductos.forEach((ele) => {
        this.salidaProductos.detalles.push({
          id: ele.id,
          salida_producto_id: ele.salida_producto_id,
          producto_id: ele.producto_id,
          consumo_producto_id: ele.consumo_producto_id,
          producto_numero: ele.producto_numero,
          codigo: ele.codigo,
          producto_nombre: ele.producto_nombre,
          producto_precio: ele.producto_precio,
          total_venta: parseFloat(ele.producto_precio) * ele.cantidad,
          unidad_medida_id: ele.unidad_medida_id,
          // unidad_medida_descripcion: unidad,
          descripcion: ele.descripcion,
          glosa: ele.glosa,
          cantidad: parseInt(ele.cantidad, 10),
          tc: parseFloat(ele.tc),
          lote_id: ele.lote_id,
          lote_numero: ele.lote_numero,
          costo_unitario: parseFloat(ele.costo_unitario),
          total: ele.cantidad * parseFloat(ele.costo_unitario),
          tipo_consumo: ele.tipo_consumo,
        });
      });
    },
    salidaProductosSendData() {
      this.salidaProductos.moneda_id = this.moneda.model.id;
      this.salidaProductos.cuenta_contable_id = this.dataModalCXP
        .parametrosContables.cuenta_debe_sp;
      let total = 0;
      for (let index = 0; index < this.salidaProductos.detalles.length;) {
        const ele = this.salidaProductos.detalles[index];
        index += 1;
        ele.fecha = (!ele.created_at) ? this.getFechaFormat(ele.fecha, 'YYYY-MM-DD HH:mm:ss') : ele.created_at;
        total += parseFloat(ele.total);
      }
      this.salidaProductos.total = total;
      return this.salidaProductos;
    },
    fullformateDate(fecha) {
      const fechaFormat = this.formatearDate(fecha);
      const day = fechaFormat.getDate();
      const month = fechaFormat.getMonth();
      const year = fechaFormat.getFullYear();
      const hora = (fechaFormat.getHours() < 10) ? `0${fechaFormat.getHours()}` : fechaFormat.getHours();
      const minuto = (fechaFormat.getMinutes() < 10) ? `0${fechaFormat.getMinutes()}` : fechaFormat.getMinutes();
      return `${day}-${month}-${year} H:${hora}:${minuto}`;
    },
    openModalPieza() {
      this.$bvModal.show('modal-pieza');
    },
    openModalCierre() {
      this.$bvModal.show('modal-cierre');
    },
    ...mapMutations('modalPieza', [
      'cargarModalPieza',
      'cargarSalaCamaID',
    ]),
    ...mapActions('modalPieza', [
      'resetModalPieza',
    ]),
    eliminarServiciosInternos(item) {
      const NRO = item.numero;
      if (NRO === '-') {
        const INDEX = this.servicios.findIndex((el) => el.customId === item.customId);
        this.servicios.splice(INDEX, 1);
      } else {
        const INDEX = this.servicios.findIndex((el) => el.customId === item.customId);
        this.servicios[INDEX].venta_servicio_estado = false;
      }
    },
    addServiciosInternos(data) {
      const service = data.servicios;
      this.servicios.push(service);
      // this.$refs.theModalPreCierre.restaurar();
    },
    setServicesData() {
      this.servicios.forEach((item) => {
        this.$set(item, 'venta_servicio_estado', true);
        this.$set(item, 'customId', item.id);
        const glosa = JSON.parse(item.glosas);
        // eslint-disable-next-line no-param-reassign
        item.glosas = glosa;
        // this.serviciosAllInternos.forEach((ele) => {
        //   if (item.tipo_servicio_id === null) {
        //     if (item.honorario_medico_id === ele.id) {
        //       this.$set(item, 'tipo_servicio_nombre', ele.tipo_servicio_nombre);
        //     }
        //   } else if (item.tipo_servicio_id === ele.id) {
        //     this.$set(item, 'tipo_servicio_nombre', ele.tipo_servicio_nombre);
        //   }
        // });
      });
      // this.agregarCategoriaServicios();
      this.convertirAcadenaGlosa();
    },
    convertirAcadenaGlosa() {
      let TEXTOGLOSA = '';
      this.servicios.forEach((ele) => {
        ele.glosas.forEach((item, index) => {
          if (item.nombre != null) {
            if (index === 0) {
              TEXTOGLOSA = item.nombre;
              this.$set(ele, 'textoglosa', TEXTOGLOSA);
            } else {
              TEXTOGLOSA = item.nombre;
              // eslint-disable-next-line no-param-reassign
              ele.textoglosa = `${ele.textoglosa} , ${TEXTOGLOSA}`;
            }
          }
        });
      });
    },
    showModalServicioInterno(dataServicio) {
      const DATA = this.cloneObject(dataServicio);
      this.$refs.servicioInterno.showModalEventEdit(DATA);
    },
    agregarPrecierre(data) {
      this.preCierreData.total_precierre = data.total;
      this.preCierreData.saldo_precierre = data.saldo;
      this.preCierreData.anticipo_precierre = data.anticipo;
      this.preCierreData.glosa_precierre = data.glosa;
      this.preCierreData.estado_precierre = data.estado_pre_cierre;
      this.preCierreData.custom_id = data.custom_id;
      this.preCierreData.hora = data.hora;
      this.preCierreData.fecha = data.fecha;
      this.preCierre = data.isPrecierre;
    },
    agregarCategoriaServicios() {
      this.servicios.forEach((item, index) => {
        this.categoriaServiciosInternos.forEach((ele) => {
          if (item.categoria_medico_id === ele.id) {
            let nombreServicio = item.tipo_servicio_nombre;
            nombreServicio = `${nombreServicio} - ${ele.nombre}`;
            // eslint-disable-next-line no-param-reassign
            this.servicios[index].tipo_servicio_nombre = nombreServicio;
          }
        });
      });
    },
    cargarModalPreCierre(data) {
      if (data.precierre) {
        // true tiene un precierre y esta activo
        this.preCierreData.id = data.id;
        this.preCierreData.total_precierre = data.total;
        this.preCierreData.anticipo_precierre = data.anticipo;
        this.preCierreData.saldo_precierre = data.saldo;
        this.preCierreData.glosa_precierre = data.glosa;
        this.preCierreData.estado_precierre = data.precierre;
        this.preCierreData.fecha = data.fecha;
        this.preCierreData.hora = data.hora;
        this.preCierre = data.precierre !== 0;
        this.preCierreData.usuario = data.usuario;
      } else {
        // tiene precierre pero esta desabiitado
        this.preCierreData.id = data.id;
        this.preCierreData.total_precierre = 0;
        this.preCierreData.anticipo_precierre = (this.dataPaciente.anticipo)
          ? this.dataPaciente.anticipo : 0;
        this.preCierreData.saldo_precierre = 0;
        // this.preCierreData.glosa_precierre = data.glosa;
        this.preCierreData.estado_precierre = data.precierre;
        this.preCierre = data.precierre !== 0;
        this.preCierreData.usuario = data.usuario;
      }
    },
    cargarDatosPreCierre() {
      const TOTAL = parseFloat(this.serviciosInternos) + parseFloat(this.centasXPagar)
      + parseFloat(this.consumoProducto);
      const SALDO = parseFloat(TOTAL) - parseFloat(this.preCierreData.anticipo_precierre);
      this.preCierreData.total_precierre = TOTAL;
      this.preCierreData.saldo_precierre = SALDO;
    },
    limpiarPreCierre() {
      this.preCierreData.total_precierre = 0;
      // this.preCierreData.anticipo_precierre = 0;
      this.preCierreData.saldo_precierre = 0;
      this.preCierreData.glosa_precierre = null;
      this.preCierreData.estado_precierre = false;
      this.preCierreData.custom_id = new Date().getTime();
      // marcar el estado del cierre como false;
      this.cierre.estado = false;
      this.cierre.hora = null;
      this.cierre.glosa = null;
    },
    agregarCierre(data) {
      this.cierre.fecha = data.fecha;
      this.cierre.hora = data.hora;
      this.cierre.glosa = data.glosa;
      this.cierre.estado = data.estado;
    },
  },
  computed: {
    ...mapGetters('modalPieza', [
      'getIDItemChecked',
      'getCamaItemChecked',
      'getPisoSalaItemChecked',
    ]),
    serviciosFiltered() {
      return this.servicios.filter((item) => item.venta_servicio_estado === true);
    },
    isDisabledAlmacen() {
      return this.salidaProductos.detalles.length > 0;
    },
    serviciosInternos() {
      let total = 0;
      this.serviciosFiltered.forEach((item) => {
        total += parseFloat(item.importe);
      });
      return total;
    },
    centasXPagar() {
      let total = 0;
      this.cuentas_x_pagar.forEach((item) => {
        const tipoMoneda = this.moneda.multiselect.find(
          (el) => el.id === item.moneda_id,
        );
        if (tipoMoneda.is_principal === 0) {
          total += parseFloat(item.importe) * parseFloat(tipoMoneda.tc);
        } else {
          total += parseFloat(item.importe);
        }
      });
      return total;
    },
    consumoProducto() {
      let total = 0;
      this.salidaProductos.detalles.forEach((item) => {
        if (item.tipo_consumo === 2) {
          total += parseFloat(item.total_venta);
        }
      });
      return total;
    },
  },
};
</script>

<style scoped>
.btn-white{
  background: white;
  color: black;
}
.text-skyblue
{
  color: #61c6f2;
}
.bg-sky-light
{
  background:#a6d2eb;
}
.navbar-reubicado
{
  transform: translate(0px, -32px);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.tabla-scroll-vertical {
    overflow-y: scroll;
    height: 180px;
}
.btnOrange {
  color: white;
  background-color: #F4B375;
}
.btnTurquesa {
  color: white;
  background-color: #319ba1;
}
</style>
